<template>
  <div
    class="supplier-payment supplier-payment-999 page_bg_grey"
    v-if="isConfirm"
  >
    <section class="payment-caption-section">
      <div class="payment-caption-container">
        <div class="payment-caption-wrapper payment-product-name pt10">
          <span>{{ $t('SUPPLIER_PAYMENT.LOAD_MULTILINE_CARD') }}</span>
        </div>

        <div class="payment-caption-wrapper text-center">
          <img class="supplier-logo" src="/assets/img/card.png" />
        </div>

        <div class="payment-caption-wrapper d-flex justify-content-end pt10">
          <router-link
            :to="{
              name: 'SupplierPayment',
              params: { supplier_id: '999' },
            }"
            tag="a"
            exact
          >
            <a
              @click="handleConnection"
              class="primary-btn bg-2 cancel-btn pointer"
            >
              {{ $t('SUPPLIER_PAYMENT.CANCELATION') }}
            </a>
          </router-link>
        </div>
      </div>
    </section>
    <section class="payment-999-content m-t-20">
      <div class="payment-999-data">
        <div class="filter-part content-margin bg-white">
          <a
            @click="setActive('acc_value')"
            :class="{
              'filter-item active': contracts_type.acc_value,
              'filter-item': !contracts_type.acc_value,
            }"
          >
            <b>{{ $t('SUPPLIER_PAYMENT.ACCUMULATED_VALUE') }}</b>
          </a>
          <a
            @click="setActive('free_monthly')"
            :class="{
              'filter-item active': contracts_type.free_monthly,
              'filter-item': !contracts_type.free_monthly,
            }"
          >
            <b>{{ $t('SUPPLIER_PAYMENT.FREE_MONTHLY') }}</b>
          </a>
          <a
            @click="setActive('free_weekly')"
            :class="{
              'filter-item active': contracts_type.free_weekly,
              'filter-item': !contracts_type.free_weekly,
            }"
          >
            <b>{{ $t('SUPPLIER_PAYMENT.FREE_WEEKLY') }}</b>
          </a>
          <a
            @click="setActive('free_flexible')"
            :class="{
              'filter-item active': contracts_type.free_flexible,
              'filter-item': !contracts_type.free_flexible,
            }"
          >
            <b>{{ $t('SUPPLIER_PAYMENT.FREE_FLEXIBLE') }}</b>
          </a>
          <a
            @click="setActive('free_daily')"
            :class="{
              'filter-item active': contracts_type.free_daily,
              'filter-item': !contracts_type.free_daily,
            }"
          >
            <b>{{ $t('SUPPLIER_PAYMENT.FREE_DAILY') }}</b>
          </a>
        </div>
        <div class="result-table bg-white">
          <!--<v-text-field
            v-model="search"
            :label="$t('COMMON.SEARCH')"
            append-icon="mdi-magnify"
            ></v-text-field>-->
          <v-data-table
            :items="getContracts()"
            class="elevation-1"
            hide-default-header
            hide-default-footer
          >
            <template v-slot:top> </template>
            <template v-slot:body="props">
              <tr class="row_contract" v-for="item in props.items">
                <td>
                  <b class="type_contract">{{ item.description }}-</b>
                  <span class="profile">{{ item.display.detail[1] }}</span>
                </td>
                <td>
                  <b class="details"> {{ item.display.detail[3] }} </b>
                </td>
                <td>
                  <b class="details">
                    {{ item.display.detail[4].slice(0, 5) }}
                  </b>
                  <b class="price"> {{ item.display.detail[4].slice(5) }} </b>
                </td>
                <td>
                  <router-link
                    :to="{
                      name: 'SupplierPaymentConfirmation',
                      params: {
                        supplier_id: '999',
                        contract_number: item.transaction.contract,
                      },
                    }"
                    tag="a"
                    exact
                  >
                    <a @click="handlePaymentSubmit" class="primary-btn">{{
                      $t('SUPPLIER_PAYMENT.CLAIM')
                    }}</a>
                  </router-link>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </div>
      <div class="payment-999-result">
        <div class="result-title bg-white">
          <p class="title">
            {{ $t('SUPPLIER_PAYMENT.MULTILINE_CARD_NUMBER') }}
          </p>
          <p class="card-number">
            {{ cardDump.serial_number }}
          </p>
        </div>
        <div class="result-content bg-white">
          <div class="result-subtitle">
            <b>{{ $t('SUPPLIER_PAYMENT.MULTILINE_TRAVEL_CONTRACTS') }}</b>
          </div>
          <div class="result-list">
            <div
              class="result-detail"
              v-for="(item, i) in contracts_display"
              :key="i"
            >
              <div>
                <div>{{ item[0] }}</div>
                <div>{{ item[1] }}</div>
                <div v-if="item[2] === 'החוזה לא בתוקף'">
                  {{ $t('SUPPLIER_PAYMENT.EXPIRED') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
  </div>
  <div v-else-if="noCard">
    <div id="app" class="modal-vue">
      <!-- overlay -->
      <div class="overlay" v-if="noCard"></div>

      <!-- modal -->
      <div class="modal" v-if="noCard">
        <button class="close" @click="closeNoCard">x</button>
        <h3>{{ $t('SUPPLIER_PAYMENT.NO_CARD') }}</h3>
      </div>
    </div>
  </div>

  <div
    class="supplier-payment supplier-payment-999 page_bg_grey"
    v-else-if="readerState === 'READER_DISCONNECTED'"
  >
    <div class="payment-caption-wrapper payment-product-name pt10">
      <span>{{ $t('SUPPLIER_PAYMENT.RAVKAV_READER_DISCONNECTED') }}</span>
    </div>
  </div>

  <div
    class="supplier-payment supplier-payment-999 page_bg_grey"
    v-else-if="readerState === 'NO_CARD'"
  >
    <div class="payment-caption-wrapper payment-product-name pt10">
      <span>{{ $t('SUPPLIER_PAYMENT.RAVKAV_NO_CARD') }}</span>
    </div>
  </div>

  <div
    class="supplier-payment supplier-payment-999 page_bg_grey"
    v-else-if="failureCardExtracted"
  >
    <div class="payment-caption-wrapper payment-product-name pt10">
      <span>{{ $t('SUPPLIER_PAYMENT.RAVKAV_FAILURE_CARD_EXTRACTED') }}</span>
    </div>
  </div>

  <div
    class="supplier-payment supplier-payment-999 page_bg_grey"
    v-else-if="readerState !== 'READY'"
  >
    <div class="payment-caption-wrapper payment-product-name pt10">
      <span>{{ $t('SUPPLIER_PAYMENT.RAVKAV_NO_CARD') }}</span>
      <span class="error-color">{{ readerState }}</span>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  components: {
    Loading,
  },
  name: 'SupplierPaymentResult999',
  props: {
    msg: String,
  },
  data: () => {
    return {
      search: null,
      contracts_type: {
        acc_value: true,
        free_monthly: false,
        free_weekly: false,
        free_flexible: false,
        free_daily: false,
      },

      isCharge: true,
      showCancel: false,
      showModal: false,
      priceForClient: null,
      description: null,
      showPopup: false,
      priceForSupplier: null,
      index: null,
      phone_number: null,
    };
  },
  created() {},
  computed: {
    ...mapState({
      failureCardExtracted: (state) =>
        state.chargeRavkav_module.failureCardExtracted,
      readerState: (state) => state.chargeRavkav_module.readerState,
      isConfirm: (state) => state.chargeRavkav_module.isConfirm,
      contracts: (state) => state.chargeRavkav_module.contracts,
      contracts_display: (state) => state.chargeRavkav_module.contracts_display,
      cardDump: (state) => state.chargeRavkav_module.cardDump,
      contrats_acc_value: (state) =>
        state.chargeRavkav_module.contrats_acc_value,
      contrats_free_monthly: (state) =>
        state.chargeRavkav_module.contrats_free_monthly,
      contrats_free_weekly: (state) =>
        state.chargeRavkav_module.contrats_free_weekly,
      contrats_free_flexible: (state) =>
        state.chargeRavkav_module.contrats_free_flexible,
      contrats_free_daily: (state) =>
        state.chargeRavkav_module.contrats_free_daily,
      loading: (state) => state.chargeRavkav_module.loading,
      connectionState: (state) => state.chargeRavkav_module.connectionState,
      noCard: (state) => state.chargeRavkav_module.noCard,
    }),
    supplierId() {
      return this.$route.params.supplier_id;
    },
  },
  methods: {
    ...mapMutations('supplier_payment', {
      setCurrentPaymentData: 'setCurrentPaymentData',
    }),
    ...mapActions('chargeRavkav_module', {
      closeNoCard: 'closeNoCard',
      closeConnection: 'closeConnection',
    }),
    handleConnection() {
      this.closeConnection();
    },
    setActive(type) {
      for (let index in this.contracts_type) {
        if (index === type) {
          this.contracts_type[index] = true;
        } else {
          this.contracts_type[index] = false;
        }
      }
    },
    getContracts() {
      if (this.contracts_type.acc_value === true) {
        return this.contrats_acc_value;
      }
      if (this.contracts_type.free_daily === true) {
        return this.contrats_free_daily;
      }
      if (this.contracts_type.free_monthly === true) {
        return this.contrats_free_monthly;
      }
      if (this.contracts_type.free_weekly === true) {
        return this.contrats_free_weekly;
      }
      if (this.contracts_type.free_flexible === true) {
        return this.contrats_free_flexible;
      }
    },
    handlePaymentSubmit() {
      let obj = this;
      const supplierId = obj.supplierId;
      this.setCurrentPaymentData({
        supplier_id: supplierId,
        payment_data: {
          supplierId: supplierId,
          itemId: 2076,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.error-color {
  color: red;
}

.modal-vue .overlay {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-vue .modal {
  position: relative;
  width: 300px;
  z-index: 9999;
  margin: 0 auto;
  padding: 20px 30px;
  background-color: #fff;
  color: black;
  top: 200px;
}

.modal-vue .close {
  position: absolute;
  top: 10px;
  right: 10px;
}
.row_contract {
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
  height: 65px;
}
.elevation-1 td {
  margin-left: 85px;
  margin-top: 20px;
}
.type_contract {
  font-size: 20px;
}
.details {
  font-size: 20px;
}
.profile {
  color: #b2bbc2;
}
.price {
  color: red;
  font-size: 30px;
}
.supplier-payment-999 {
  .card::before {
    content: none;
  }
  .supplier-logo {
    width: 120px;
  }
  .payment-product-name {
    font-size: 30px;
  }
  .cancel-btn {
    width: 160px;
  }
  .payment-999-content {
    display: flex;
    justify-content: space-between;
    padding: 0 5px;
    .payment-999-data {
      width: 100%;
      margin-left: 20px;
      margin-right: unset;
      .filter-part {
        margin: 0 !important;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 20px;
        margin-bottom: 10px;
        .filter-item {
          padding: 5px 10px;
          border-radius: 18px;
          margin: 0 12px;
          cursor: pointer;
          &.active {
            background-color: #6751a3;
            color: white;
          }
          b {
            white-space: nowrap;
          }
        }
        .otherFilter {
          max-width: 160px;
          height: 32px;
        }
      }
      .result-table {
        padding: 0 5px;
        .search-bar {
          max-width: 170px;
          margin-bottom: 10px;
        }
        .primary-btn {
          max-width: 150px;
          height: 40px;
          width: 100px;
        }
      }
    }
    .payment-999-result {
      width: 30%;
      .result-title {
        display: flex;
        align-items: center;
        padding: 15px 0;
        margin-bottom: 10px;
        .title {
          margin: 0 20px;
        }
        .card-number {
          margin: 0 30px;
        }
      }
      .result-content {
        padding: 0 5px;
        .result-subtitle {
          border-bottom: 1px solid #dbdbdb;
          padding: 15px 20px;
        }
        .result-list {
          .result-detail {
            border-bottom: 1px solid #dbdbdb;
            padding: 15px 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #b2bbc2;
          }
        }
      }
    }
  }
}
</style>